import { lazy } from 'react';

const Main      =       lazy(() => import('pages/Main/Main'));
const Feedback  =       lazy(() => import('pages/Feedback/Feedback'));
const Offer     =       lazy(() => import('pages/Offer/Offer'));
const Offer2    =       lazy(() => import('pages/OfferAndroid/OfferAndroid'));
const FAQ       =       lazy(() => import('pages/FAQ/FAQ'));
const Policy    =       lazy(() => import('pages/Policy/Policy'));
const Cookies   =       lazy(() => import('pages/Cookies/Cookies'));
const User      =       lazy(() => import('pages/User/User'));
const NotFound  =       lazy(() => import('pages/NotFound/NotFound'));

export enum publicPath {
    main        =       '/',
    user        =       '/user/:uuid',
    offer       =       '/offer',
    offer2      =       '/oferta-v-prilozhenii',
    faq         =       '/faq',
    feedback    =       '/feedback',
    policy      =       '/privacypolicy',
    cookies     =       '/cookies',
    set_cookies =       '/cookies?scroll=bottom',
    other       =       '*',
}

export const publicRoutes = [
    {
        path: publicPath.main,
        page: <Main/>,
    },
    {
        path: publicPath.user,
        page: <User/>,
    },
    {
        path: publicPath.feedback,
        page: <Feedback/>,
    },
    {
        path: publicPath.offer,
        page: <Offer/>,
    },
    {
        path: publicPath.offer2,
        page: <Offer2/>,
    },
    {
        path: publicPath.faq,
        page: <FAQ/>,
    },
    {
        path: publicPath.policy,
        page: <Policy/>,
    },
    {
        path: publicPath.cookies,
        page: <Cookies/>,
    },
    {
        path: publicPath.set_cookies,
        page: <Cookies/>,
    },
    {
        path: publicPath.other,
        page: <NotFound/>,
    },
];